<template>
  <surround-box :width="330" :title="soilInfo.name">
    <span class="iconfont icon-guanbi1 close" @click="close"></span>
    <div class="outer-page">
      <!-- <div class="title">
        <span>土壤墒情</span>
      </div> -->
      <div class="select">
        <Select style="width: 100%" v-model="siteGuid">
          <Option
            :value="item.sensorGuids"
            v-for="item in getSites"
            :key="item.guid"
            >{{ item.name }}</Option
          >
        </Select>
      </div>
      <div class="soil-detail">
        <ul>
          <li v-for="(item, index) in data" :key="index">
            <div style="display: flex">
              <div style="width: 76px; height: 76px; border: 1px dashed #fff">
                <img
                  :src="item.servicePath + item.imgUrl"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div class="soil-ph soil-display">
                <span
                  style="
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 36px;
                    color: #1dd8be;
                  "
                  >{{ item.measure }}</span
                >
                <span>{{ item.sensorCategoryName }}</span>
              </div>
            </div>
            <div style="text-align: right" class="soil-display">
              <div style="cursor: pointer" @click="soilEcharts(item)">
                <img
                  style="width: 20px; height: 20px"
                  title="查看近期数据曲线"
                  src="imgs/line.png"
                  alt=""
                />
              </div>
              <div>{{ item.updateTime }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </surround-box>
</template>

<script>
import { mapState } from "vuex";
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  name: "",
  props: {
    soilInfo: Object,
  },
  data() {
    return {
      data: [],
      siteGuid: "",
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.map.sites,
    }),
    getSites() {
      return this.sites.filter(
        (item) => item.siteType == 1 || item.siteType == 6
      );
    },
  },
  methods: {
    close() {
      this.$emit("on-close");
    },
    soilEcharts(item) {
      this.$emit("show-chart", {
        ...item,
        siteInfo: { ...this.soilInfo },
        sensorList: this.data,
      });
    },
    getDetail(sensorGuids) {
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        guidArr: sensorGuids,
      }).then((res) => {
        this.data = res.list;
      });
    },
  },
  mounted() {
    if (this.soilInfo) {
      this.siteGuid = this.soilInfo.sensorGuids;
    }
    this.getDetail();
    // console.log(this.soilInfo);
  },
  watch: {
    soilInfo(n) {
      if (!n) return;
      this.siteGuid = n.sensorGuids;
    },
    siteGuid(n) {
      this.getDetail(n);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-select-dropdown {
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
}
/deep/ .ivu-select-item {
  color: #fff;
}
/deep/ .ivu-select-item:hover {
  // background: rgba(23, 34, 60, 0.4);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
}
/deep/ .ivu-select-item-focus {
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
}
/deep/ .ivu-select-selection {
  // background: rgba(23, 34, 60, 0.6);
  // border: 1px solid rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
  box-shadow: 0;
}
/deep/.ivu-select-visible .ivu-select-selection {
  // border-color: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  outline: 0;
  box-shadow: 0 0 0 2px rgb(23 34 60 / 20%);
}
/deep/ .ivu-select {
  color: #fff;
}
/deep/ .green-theme .ivu-select-item-selected,
.green-theme .ivu-select-item-selected:hover {
  color: #fff;
}
/deep/ .ivu-select-selection:hover {
  // border-color: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
  box-shadow: 0;
}
/deep/ .ivu-select-selection:focus {
  // border-color: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
  box-shadow: 0;
}
.outer-page {
  width: 100%;
  height: 100%;
  // background: rgba(23, 34, 60, 0.6);
  border-radius: 4px;
  color: #fff;
  padding: 16px;
  position: relative;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 6px;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 10px;
    &:hover {
      color: #fff;
    }
  }
  .icon-guanbi1 {
    font-size: 9px;
  }
  .select {
    margin-top: 10px;
  }
  .soil-detail {
    margin-top: 10px;
    width: 100%;
    ul {
      height: 210px;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        padding: 7px;
        height: 90px;
        margin-top: 14px;
        // background-color: rgba(23, 34, 60, 0.6);
        background: linear-gradient(
          180deg,
          rgba(14, 56, 50, 0.28) 0%,
          rgba(3, 42, 34, 0.81) 100%
        );
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        .soil-display {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .soil-ph {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>